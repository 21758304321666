<template>
    <div style="background-color:#FFFFFF">
        <navbarComponent/>
        <b-container class="p-2">
        <b-row align-h="center">
            <b-col sm="8">
            <b-card class="p-2">
                <b-form >
                    <b-card-text>
                    <h5><b>Registrasi</b></h5>
                    </b-card-text>
                    <hr />
                    <b-input-group size="sm" prepend="Email" class="mb-2">
                        <b-form-input type="email" required v-model="iEmail"></b-form-input>
                    </b-input-group>
                    <div v-show="iEmail == null || iEmail == ''" class="mb-2 text-left">
                        <span class="validationtext">{{vmEmail}}</span>
                    </div>
                    <b-input-group size="sm" prepend="Password" class="mb-2">
                        <b-form-input type="password" required v-model="iPass"></b-form-input>
                    </b-input-group>
                    <div v-show="iPass == null || iPass == ''" class="mb-2 text-left">
                        <span class="validationtext">{{vmPass}}</span>
                    </div>
                    <b-input-group size="sm" prepend="Ketik Ulang Password" class="mb-2">
                        <b-form-input type="password" required v-model="iVerifyPass"></b-form-input>
                    </b-input-group>
                    <div v-show="iPass != iVerifyPass || iVerifyPass == ''" class="mb-2 text-left">
                        <span class="validationtext">{{vmVerifyPass}}</span>
                    </div>
                    <b-input-group size="sm" prepend="Nama Lengkap" class="mb-2">
                        <b-form-input v-model="iName" required></b-form-input>
                    </b-input-group>
                    <div v-show="iName == null || iName == ''" class="mb-2 text-left">
                        <span class="validationtext">{{vmName}}</span>
                    </div>
                    <br>
                    <b-row>
                        <b-col sm="4">
                            <b-button @click="toPage('/')" size="sm" block variant="outline-success">Login</b-button>
                        </b-col>
                        <b-col sm=5>
                            
                        </b-col>
                        <b-col sm="3">
                            <b-button @click="registerData" size="sm" block variant="primary">Daftar</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
            </b-col>
        </b-row>
        </b-container>
        <b-modal
            id="modal"
            title="Registrasi"
            hide-footer
            centered
            >
            <b-form-group>
                <span>{{registerMessage}}</span>
            </b-form-group>
        </b-modal>
        <hr />
        <b-container>
        <Footer />
        </b-container>
    </div>
</template>

<script>
import axios from "axios";
import navbarComponent from "../components/NavbarBlank.vue";
export default {
    data() {
        return {
            iEmail: '',
            iPass: '',
            iVerifyPass: '',
            iName: '',
            vmEmail: "*Email wajib diisi",
            vmPass: "*Password wajib diisi",
            vmVerifyPass: "*Password tidak sesuai",
            vmName: "*Nama lengkap wajib diisi",
            registerMessage: ''
        };
    },
    components: {
        navbarComponent
    },
    methods: {
        registerData() {
            let gEmail = this.iEmail;
            let gPass = this.iPass;
            let gVerifyPass = this.iVerifyPass;
            let gName = this.iName;
            if(
                (
                    gEmail != '' && gVerifyPass != '' && gPass != '' && gName != '' && gVerifyPass == gPass
                ) 
            ){
                axios
                .get("https://backend-sma-soedirman.uss-utbk.com//validateuser",{
                    params: {
                        userEmail: gEmail
                    }
                })
                .then(res => {
                    let list = res.data.values;
                    let isUserExist = list.length;
                    if (isUserExist > 0) {
                        let message = "Email sudah terdaftar"
                        this.displayMessage(message);
                    }
                    else {
                        let message = "Daftar berhasil"
                        this.displayMessage(message);
                        let object = this;
                        axios.post('https://backend-sma-soedirman.uss-utbk.com//user', {
                            userEmail: gEmail,
                            userPass: gPass,
                            userFullName: gName
                        })
                        .then(function (response) {
                            object.output = response.data;

                        })
                        .catch(function (error) {
                            object.output = error;
                        });
                        this.iEmail = '';
                        this.iPass = '';
                        this.iName = '';
                        this.toPage("/");
                        
                    }
                });
            }
            else {
                let message = "Harap lengkapi & sesuaikan data"
                this.displayMessage(message);
            }
        },
        toPage(param) {
            this.$router.push(param);
        },
        displayMessage(param){
            this.registerMessage = param;
            this.$root.$emit("bv::show::modal", "modal");
        }
    },
    created(){
        if(localStorage.getItem("userID") != null){
            this.$router.push("/homepage");
        }
    }
};
</script>
