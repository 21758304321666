    <template>
    <div class="body">
        <div :style="{ backgroundImage: 'url(' + require('@/assets/header5.jpg') + ')' }" >
            <nav class="navbar pt-2 pb-2 pl-5 pr-5  navbar-expand-lg navbar-light text-light ">
            <img width="150" src="../assets/logousswithsmasoedirman.png"/>
            </nav>
            <div style="height:470px">
            <div class="container">
                <div class="row">
                    
                <div class="col-5" style="margin-top:40px">
                    <div v-show="isRegister==1" class="card st-blue p-4 srd-25" style="border:none;">
                        <p class="st-blue-title mb-3" style="text-align:center">Daftar</p>
                        <form @submit="register()">
                            <input class="form-control sc-textbox" v-model="iForm.iEmail" type="email" placeholder="Email"/>
                            <input class="form-control sc-textbox" v-model="iForm.iPass" type="password" placeholder="Password"/>
                            <input class="form-control sc-textbox" v-model="iForm.iVerifyPass" type="password" placeholder="Ketik Ulang Password"/>
                            <input class="form-control sc-textbox" v-model="iForm.iName" type="text" placeholder="Nama Lengkap"/>
                            <p style="text-align:center;color:red">{{formMessage}}</p>
                            <button class="btn btn-warning text-white srd-25 p-2" type="submit" style="width:100%;font-size:15px;font-weight:bold">Daftar</button>
                        </form>
                        <button @click="isRegister=0;formMessage=''" class="btn btn-info text-white srd-25 p-2 mt-2" style="width:100%;font-size:15px;font-weight:bold">Kembali ke Login</button>
                    </div>
                    <div v-show="isRegister==0" class="card st-blue p-4 srd-25" style="border:none;">
                        <p class="st-blue-title mb-5" style="text-align:center">Ikuti Try out UTBK & Lolos SBMPTN 2021!</p>
                        <form @submit="login(0)">
                            <input class="form-control sc-textbox" v-model="iForm.iEmail" type="email" placeholder="Email"/>
                            <input class="form-control sc-textbox" v-model="iForm.iPass" type="password" placeholder="Password"/>
                            <p style="text-align:center;color:red">{{formMessage}}</p>
                            <button class="btn btn-warning text-white srd-25 p-2" type="submit" style="width:100%;font-size:15px;font-weight:bold">Masuk</button>
                        </form>
                        <button @click="isRegister=1;formMessage=''" class="btn btn-info text-white srd-25 p-2 mt-2" style="width:100%;font-size:15px;font-weight:bold">Belum punya akun? Daftar sekarang!</button>
                    </div>
                </div>
                <div class="col-7">
                </div>
                </div>
            </div>
        </div>
    </div>
        <div style="margin-top:-40px">
        <div class="container">
            <div class="row">
                <div class="col-sm" v-for="d in packageData" :key="d.packageID">
                    <div class="card srd-25" style="border:none;box-shadow: 5px 5px 5px -2px #bbbbbb;">
                        <div class="row pl-4 pt-4 pr-4 mt-4 ">
                            <div class="col-sm">
                            <img v-show="d.packageID==1"  src="../assets/saintek.png" 
                            class="card-img-top" alt="..." style="width:150px;">
                            <img v-show="d.packageID==2" src="../assets/soshum.png" 
                            class="card-img-top" alt="..." style="width:150px;">
                            </div>
                            <div class="col-sm">
                            </div>
                        </div>
                        <div class="card-body text-left p-5">
                            <p class="st-blue-title">{{d.packageDescription}}</p>
                            <p style="font-size:15px;" class="card-text mb-4">Cocok buat kamu yang akan menghadapi UTBK</p>
                            <hr/>
                            <p class="st-darkblue-title">Rp {{d.packagePriceDisplay}},-</p>
                        <!-- <button @click="toPage('/')" class="btn btn-warning text-white srd-25 p-2" type="submit" style="width:100%;font-size:15px;font-weight:bold">Daftar</button> -->
                        </div>
                    </div>
                </div>
             
            </div>
        </div>
        </div>
    <div style="margin-top:60px;margin-bottom:80px">
      <div class="container">
        <div class="row">
          <div class="col-5">
            <img style="width:400px" src="../assets/ussimg_1.png" class="card-img-top" alt="...">
          </div>
          <div class="col-7 text-left">
            <p class="st-blue-title-30 mb-5">Apa itu USS (UTBK Success System)?</p>
            <p style="font-size:22px">USS merupakan platform digital yang menyediakan try out UTBK online dengan sistem dan prediksi soal mirip bahkan sama dengan UTBK sesungguhnya</p>
            <p style="font-size:22px">USS siap membantu kamu dalam Persiapan menghadapi UTBK 2021</p>
            <!-- <iframe width="534" height="300" src="https://www.youtube.com/embed/aTIIwDh3eGA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
          </div>
        </div>
      </div>
    </div>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-sm">
                    <p class="st-blue-title-30">Apa saja keunggulan USS?</p>
                    <div class="card srd-25 pt-4 pl-4 pr-4" style="margin-bottom:40px;border:none;box-shadow: 5px 5px 5px -2px #bbbbbb;">
                        <div style="text-align:center">
                            <img style="width:150px;" src="../assets/icon-hot.png" 
                            class="card-img-top m-auto" alt="...">
                        </div>
                        <div class="card-body mt-4">
                            <p class="st-blue-title-25">Paket soal akurat dan update</p>
                            <p style="font-size:20px">Soal-soal hasil kajian dari Tim Litbang bimbel SG, dijamin kualitasnya, bukan copas dari internet. Tim Litbang bimbel SG telah lebih dari 16 tahun mengkaji soal-soal masuk PTN termasuk soal UTBK</p>
                        </div>
                    </div>
                    <div class="card srd-25 pt-4  pl-4 pr-4" style="margin-bottom:40px;border:none;box-shadow: 5px 5px 5px -2px #bbbbbb;">
                        <div style="text-align:center">
                            <img style="width:150px;" src="../assets/icon-challenge.png" 
                            class="card-img-top m-auto" alt="...">
                        </div>
                        <div class="card-body mt-4">
                            <p class="st-blue-title-25">Soal seru dan menantang</p>
                            <p style="font-size:20px">Soal-soal dibuat tidak hanya untuk berlatih siswa tapi sudah masuk ke level HOTS (High Order Thinking Skill)</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm">
                    <div class="card srd-25 pt-4  pl-4 pr-4" style="margin-bottom:40px;border:none;box-shadow: 5px 5px 5px -2px #bbbbbb;">
                        <div style="text-align:center">
                            <img style="width:150px;" src="../assets/icon-irt.png" 
                            class="card-img-top m-auto" alt="...">
                        </div>
                        <div class="card-body mt-4">
                            <p class="st-blue-title-25">Penilaian dengan sistem IRT</p>
                            <p style="font-size:20px">Sistem penilaian yang digunakan oleh LTMPT. Setiap soal memiliki bobot nilai yang berbeda sehingga peserta akan mendapatkan nilai sesuai dengan tingkat kesulitan soal yang dijawab. Peserta bisa tahun mana soal kategori susah, sedang dan mudah</p>
                        </div>
                    </div>
                    <div class="card srd-25 pt-4  pl-4 pr-4" style="margin-bottom:40px;border:none;box-shadow: 5px 5px 5px -2px #bbbbbb;">
                        <div style="text-align:center">
                            <img style="width:150px;" src="../assets/icon-complete.png" 
                            class="card-img-top m-auto" alt="...">
                        </div>
                        <div class="card-body mt-4">
                            <p class="st-blue-title-25">Hasil Tryout Lengkap</p>
                            <p style="font-size:20px">Hasil try out tidak hanya menunjukkan skor, tapi sekaligus akan disertai dengan prediksi kelulusan pada prodi dan PTN yang diimpikan</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
    data(){
        return {
            packageData: [],
            iForm: {
                iEmail: '',
                iPass: '',
                iVerifyPass: '',
                iName: ''
            },
            formMessage: '',
            isRegister: 0,
        }
    },
    methods: {
        toPage(param){
            this.$router.push(param);
        },
        register() {
            let iForm = this.iForm;
            let gEmail = iForm.iEmail;
            let gPass = iForm.iPass;
            let gVerifyPass = iForm.iVerifyPass;
            let gName = iForm.iName;
            if(
                (
                    gEmail != '' && gVerifyPass != '' && gPass != '' && gName != '' && gVerifyPass == gPass
                ) 
            ){
                axios
                .get("https://backend-sma-soedirman.uss-utbk.com//validateuser",{
                    params: {
                        userEmail: gEmail
                    }
                })
                .then(res => {
                    let list = res.data.values;
                    let isUserExist = list.length;
                    if (isUserExist > 0) {
                        this.formMessage = "Email sudah terdaftar"
                        // iForm.iEmail = '';
                        // iForm.iPass = '';
                        // iForm.iVerifyPass = '';
                        // iForm.iName = '';
                    }
                    else {
                        this.formMessage = "Daftar berhasil"
                        let object = this;
                        axios.post('https://backend-sma-soedirman.uss-utbk.com//user', {
                            userEmail: gEmail,
                            userPass: gPass,
                            userFullName: gName
                        })
                        .then(function (response) {
                            object.output = response.data;
                            object.login(1);

                        })
                        .catch(function (error) {
                            object.output = error;
                            // iForm.iEmail = '';
                            // iForm.iPass = '';
                            // iForm.iVerifyPass = '';
                            // iForm.iName = '';
                        });
                        
                    }
                });
            }
            else {
                this.formMessage = "Harap lengkapi dan sesuaikan data"
                // iForm.iEmail = '';
                // iForm.iPass = '';
                // iForm.iVerifyPass = '';
                // iForm.iName = '';
            }
        },
        login(param){
            let iForm =  this.iForm;
            let gEmail = iForm.iEmail;
            let gPass = iForm.iPass;
            let registerParam = param;
            axios
            .get("https://backend-sma-soedirman.uss-utbk.com//login",{
                params: {
                    userEmail : gEmail,
                    userPass : gPass
                }
            })
            .then(res => {
                let list = res.data.values;
                try {
                    let userID = list[0].userID;
                    let roleID = list[0].roleID;
                    localStorage.setItem("userID", userID);
                    localStorage.setItem("roleID", roleID);
                    this.formMessage = "Berhasil";
                    if(roleID == 3){
                        if(registerParam == 1){
                            this.toPage("/profile");
                        }
                        else {
                            this.toPage("/homepage");
                        }
                    }
                    else {
                        this.toPage("/admindashboard");
                    }
                }
                catch (error){
                    localStorage.removeItem("userID");
                    this.formMessage = "Tidak terdaftar";
                }

            })
        }
    },
    created(){
        if(localStorage.getItem("userID") != null){
            this.$router.push("/homepage");
        }
        axios
        .get("https://backend-sma-soedirman.uss-utbk.com//package")
        .then(res => {
            this.packageData = res.data.values;
        });
    }
}
</script>

<style scoped>

.body {
  background-color: #F3F7FA;
}

.sbg-blue {
  background-color: #3B73C5;
}

.sbg-purple {
  background-color: #733FC0;
}
.sbg-orange {
  background-color: #FF7600;
}
.sbtn-orange {
  background-color: #FF7600;
  color: #FFFFFF;
}
.sbtn-orange:hover {
  background-color: #ff9f4c;
  color: #FFFFFF;
}
.st-purple {
  color: #733FC0;
}
.st-blue {
  color: #3B73C5;
}
.st-blue-title {
  color: #3B73C5;
  font-size:20px;
  font-weight: bold;
}
.st-darkblue-title {
  color: #1D3962;
  font-size:35px;
  font-weight: bold;
}
.st-blue-title-30 {
  color: #3B73C5;
  font-size:30px;
  font-weight: bold;
}
.st-blue-title-25 {
  color: #3B73C5;
  font-size:30px;
  font-weight: bold;
}
.st-white {
  color: #FFFFFF;
}
.st-gray {
  color: #c9c9c9;
}
.st-darkblue {
  color: #1D3962;
}
.srd-25 {
  border-radius: 25px;
}
.sbn-navbar {
    color: #FFF;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    margin-left:8px;
    font-weight: bold;
    cursor: pointer;
}
.sbn-navbar-active {
    color: #3B73C5;
    background-color: #FFF;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    margin-left:8px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}
.sbn-navbar-invert {
    color: #3B73C5;
    background-color: #FFF;
    font-size: 14px;
    padding: 10px;
    margin-left:30px;
    font-weight: bold;
    cursor: pointer;
}
.sbn-navbar:hover {
    background-color: #FFF;
    color: #3B73C5;
    padding: 10px;
    border-radius: 10px;
    margin-left:8px;
    text-decoration: none;
}
.sbn-navbar-invert:hover {
    color: #3B73C5;
    background-color: #FFF;
    padding: 10px;
    font-weight: bold;
    text-decoration: none;
}
.sc-textbox {
    border-radius:10px;
    color: #3B73C5;
    font-size:14px;
    margin-bottom:10px;
}

.sc-textbox:focus {
    border-radius:10px;
    color: #3B73C5;
}
</style>