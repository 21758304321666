<template>
    <div class="body">
        {{roleID}}
        <div class="sidenav">
            <div>
                <img width="150" src="../assets/logousswithsmasoedirman.png"/>
            </div>
            <hr class="sidebar-hr"/>
            <div class="sidebar-menu">
                <ul>
                    <li @click="pageHierarchy=1">
                        <a>
                            Dashboard
                        </a>
                    </li>
                    <li @click="toPage('/');removeAnyStoredInformation()">
                        <a>
                            Logout
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="main">
                <div class="row">
                    <div class="mt-4">
                        <h5>
                        USS Dashboard | {{display.fullName}}
                        </h5>
                    </div>
                </div>
                <hr/>
                <div v-show="pageHierarchy==2">
                    <div class="row">
                        <h5>
                            Detil Paket
                        </h5>
                    </div>
                    <div class="row">
                        <button class="sbtn-blue" @click="forceCalculateAllWeightFactor()">Kalkulasi Bobot secara Manual</button>
                    </div>
                    <div class="row">
                        <p>Saat jumlah TO yang sudah terselesaikan mencapai <b>500</b>, maka bobot akan otomatis terkalkulasikan.</p>
                    </div>
                    <div class="row">
                        <div class="col-sm">
                            <div class="card scr-height" style="border-radius:10px;border:none;box-shadow: 6px 6px 6px -3px #bbbbbb;">
                                <div class="row" >
                                        <b-table class="stbl" borderless :items="selectedPackageData" :fields="filterSelectedPackageData">
                                            
                                        </b-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="pageHierarchy==1">
                    <div class="row">
                        <h5>
                            Insight
                        </h5>
                    </div>
                    <div class="row">
                        <div class="col-sm">
                            <div class="card scr-height" style="border-radius:10px;border:none;box-shadow: 6px 6px 6px -3px #bbbbbb;">
                                <div class="row" >
                                    <div class="col-4">
                                        <img style="width:60px;" src="https://pahamify.com/wp-content/uploads/2020/08/belajar-konsep.svg" >
                                    </div>
                                    <div class="col-8 scr-1">
                                        <span class="scr-1-title">User</span><br/>
                                        <span class="scr-1-content">{{allUserData.length}}</span>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-sm">
                            <div class="card scr-height" style="border-radius:10px;border:none;box-shadow: 6px 6px 6px -3px #bbbbbb;">
                                <div class="row" >
                                    <div class="col-4">
                                        <img style="width:60px;" src="https://pahamify.com/wp-content/uploads/2020/08/persiapan-kulah.svg"  >
                                    </div>
                                    <div class="col-8 scr-1">
                                        <span class="scr-1-title">Pemesanan TO</span><br/>
                                        <span class="scr-1-content">{{totalOrderedPackage}}</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm">
                            <div class="card scr-height" style="border-radius:10px;border:none;box-shadow: 6px 6px 6px -3px #bbbbbb;">
                                <div class="row" >
                                    <div class="col-4">
                                        <img style="width:60px;" src="https://pahamify.com/wp-content/uploads/2020/08/belajar-konsep.svg" >
                                    </div>
                                    <div class="col-8 scr-1">
                                        <span class="scr-1-title">TO Terbayar</span><br/>
                                        <span class="scr-1-content">{{totalPaidPackage}}</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm">
                            <div class="card scr-height" style="border-radius:10px;border:none;box-shadow: 6px 6px 6px -3px #bbbbbb;">
                                <div class="row" >
                                    <div class="col-4">
                                        <img style="width:60px;" src="https://pahamify.com/wp-content/uploads/2020/08/persiapan-kulah.svg"  >
                                    </div>
                                    <div class="col-8 scr-1">
                                        <span class="scr-1-title">TO Terselesaikan</span><br/>
                                        <span class="scr-1-content">{{totalCompletedPackage}}</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div class="row">
                        <h5>
                            Daftar Paket Aktif
                        </h5>
                    </div>
                    <div class="row">
                        <span v-for="d in packageData" :key="d.packageID">
                            <div @click="openPackage(d.packageID)" class="card scr-height p-4 mr-3 mb-3 scr-package" style="cursor:pointer;border-radius:10px;border:none;box-shadow: 6px 6px 6px -3px #bbbbbb;">
                                <img class="mb-3" style="width:60px;" src="https://pahamify.com/wp-content/uploads/2020/08/tryout.svg"  >
                                <h4>{{d.packageDescription}}</h4>
                                <span v-show="d.packageWeightFactorCalculated==1" class="scr-weightfactor-status-ok mt-2">Bobot sudah dihasilkan</span>
                                <span v-show="d.packageWeightFactorCalculated==0" class="scr-weightfactor-status-no mt-2">Bobot belum dihasilkan</span>
                            </div>
                        </span>
                        <!-- <div class="card scr-height p-4 mr-3 mb-3 scr-package" style="cursor:pointer;border-radius:10px;border:none;box-shadow: 6px 6px 6px -3px #bbbbbb;">
                            <img class="mb-3" style="width:60px;" src="https://pahamify.com/wp-content/uploads/2020/08/tryout.svg"  >
                            <h4>Saintek 1</h4>
                            <span class="scr-weightfactor-status-ok mt-2">Bobot sudah dihasilkan</span>
                        </div>
                        <div class="card scr-height p-4 mr-3 mb-3 scr-package" style="cursor:pointer;border-radius:10px;border:none;box-shadow: 6px 6px 6px -3px #bbbbbb;">
                            <img class="mb-3" style="width:60px;" src="https://pahamify.com/wp-content/uploads/2020/08/tryout.svg"  >
                            <h4>Soshum 1</h4>
                            <span class="scr-weightfactor-status-no mt-2">Bobot belum dihasilkan</span>
                        </div> -->
                    </div>
                    <hr/>
                </div>
            </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    methods:{
        removeAnyStoredInformation(){
            localStorage.removeItem("userID");
            localStorage.removeItem("userPackageID");
            localStorage.removeItem("packageID");
        },
        toPage(param){
            this.$router.push(param);
        },
        forceCalculateAllWeightFactor(){
            let packageID = this.selectedPackageID;
            axios  
            .get("https://backend-sma-soedirman.uss-utbk.com//forceallweightfactor",{
                params: {
                    packageID: packageID
                }
            })
            .then(res => { 
                let data = res.data.values; 
                console.log(data); 
            });
        },
        openPackage(packageID) {
            this.selectedPackageID = packageID;
            console.log(packageID);
            this.pageHierarchy = 2; //Package Detail
            this.selectedPackageData = [];
            axios
            .get("https://backend-sma-soedirman.uss-utbk.com//n-subpackage-packageid",{
                params: {
                    packageID: packageID
                }
            })
            .then(res => {
                this.selectedPackageData = res.data.values;
            });
        }
    }, 
    afterCreate() {
        let userID = localStorage.getItem("userID");
        this.roleID = localStorage.getItem("roleID");
        console.log(this.roleID);
        if(localStorage.getItem("userID") == null){
            this.$router.push("/");
        }
    },
    beforeCreate() {
        let userID = localStorage.getItem("userID");

            axios
            .get("https://backend-sma-soedirman.uss-utbk.com//package")
            .then(res => {
                this.packageData = res.data.values;
            });
            axios
            .get("https://backend-sma-soedirman.uss-utbk.com//userinformation",{
                params: {
                    userID: userID
                }
            })
            .then(res => {
                this.userData = res.data.values;
                let userData = res.data.values;
                this.display.fullName = userData[0].userFullName;
            });
            axios
            .get("https://backend-sma-soedirman.uss-utbk.com//package")
            .then(res => {
                this.packageData = res.data.values;
            });
            axios
            .get("https://backend-sma-soedirman.uss-utbk.com//users")
            .then(res => {
                this.allUserData = res.data.values;
            });
            axios
            .get("https://backend-sma-soedirman.uss-utbk.com//n-alluserpackagesummary")
            .then(res => {
                this.allUserPackageSummary = res.data.values;
                let ref = this;
                for(let i = 0; i < ref.allUserPackageSummary.length;i++){
                    let userPackageStatus = ref.allUserPackageSummary[i].userPackageStatus;
                    if(userPackageStatus == 'UNPAID'){
                        ref.totalOrderedPackage = ref.allUserPackageSummary[i].totalUserPackage
                    }
                    else if(userPackageStatus == 'PAID'){
                        ref.totalPaidPackage = ref.allUserPackageSummary[i].totalUserPackage
                    }
                    else if(userPackageStatus == 'COMPLETED'){
                        ref.totalCompletedPackage = ref.allUserPackageSummary[i].totalUserPackage
                    }
                }
            });

    },
    data() {
        return {
            roleID: null,
            selectedPackageID: null,
            userData: [],
            display: {
                fullName: ''
            },
            pageHierarchy: 1,
            packageData: [],
            selectedPackageData: [],
            filterSelectedPackageData: [
                {
                    key: 'subPackageOrder', label: 'NO', sortable: false
                },
                {
                    key: 'subPackageExamGroupDesc', label: 'GRUP', sortable: false
                },
                {
                    key: 'subPackageDescription', label: 'DESKRIPSI', sortable: false
                },
                {
                    key: 'subPackageTotalQuestion', label: 'JUMLAH SOAL', sortable: false
                }
            ],
            allUserData: [],
            allUserPackageSummary: [],
            totalOrderedPackage: null,
            totalPaidPackage: null,
            totalCompletedPackage: null
        }
    }
}
</script>

<style scoped>

.sbtn-blue {
    background-color: #3B73C5;
    color: #FFF;
    border-radius: 10px;
    padding: 8px 20px;
    font-weight: bold;
    font-size: 14px;
    outline: none;
    border: none;
    text-align: left;
    transition: 0.2s;
}
.sbtn-blue:hover {
    background-color: #1D3962;
    color: #FFF;
    transition: 0.2s;
}
/deep/ .stbl tbody td {
    border-bottom: 1px solid #F3F7FA;
}
.stbl {
    margin-top:20px;
    font-size:12px;
}

.sidebar-menu ul {
    padding:0px;
}
.sidebar-menu ul li {
    list-style: none;
    padding:0px;
    cursor: pointer;
    padding-top:10px;
    padding-left:10px;
    padding-bottom:10px;
    border-radius:10px;
    transition: 0.1s;
    margin-bottom: 5px;

}
.sidebar-menu ul li:hover {
    background-color:#FFF;
    color: #3B73C5;
    transition: 0.1s;

    
}
.sidebar-hr {
    background-color: #FFF;
    height: 0.3px;
}
.scr-1 {
    text-align: right
}
.scr-height {
    height: 100%;
}
.scr-1-title{
    font-size:15px;
}
.scr-1-content {
    font-size:26px;

}
.sidenav {
  height: 100%;
  width: 260px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  font-size: 14px;
  background-color: #3B73C5;
  overflow-x: hidden;
  padding: 20px 20px;
  text-align: left;
  color: #FFF;
  border-right: 1px solid #d8d8d8;
}

.scr-package {
    transition: 0.3s;
}
.scr-package:hover {
    transition: 0.3s;
    background-color: #3B73C5;
    color: #FFF;
}
.scr-weightfactor-status-ok {
    padding: 5px 15px;
    color: #FFF;
    background-color: #3bc546;
    border-radius: 10px;
}
.scr-package:hover .scr-weightfactor-status-ok {
    color: #3bc546;
    background-color: #FFF;
}
.scr-weightfactor-status-no {
    padding: 5px 15px;
    color: #FFF;
    background-color: #c53b3b;
    border-radius: 10px;
}
.scr-package:hover .scr-weightfactor-status-no {
    color: #c53b3b;
    background-color: #FFF;
}


.main {
  margin-left: 260px; /* Same as the width of the sidenav */
  font-size: 14px; /* Increased text to enable scrolling */
  padding: 0px 10px;
  text-align: left;
}
.main .row {
    padding: 0px;
    margin-left: 5px;
    padding-top:10px;
    padding-bottom:10px;
    padding-right: 20px;
}
.main .row .col-sm {
    padding:0px;
    padding-right: 30px;
}
.body {
  background-color: #F3F7FA;
  /* background-color: #F4F3EF; */
}

.sbg-blue {
  background-color: #3B73C5;
}

.sbg-purple {
  background-color: #733FC0;
}
.sbg-orange {
  background-color: #FF7600;
}
.sbtn-orange {
  background-color: #FF7600;
  color: #FFFFFF;
}
.sbtn-orange:hover {
  background-color: #ff9f4c;
  color: #FFFFFF;
}
.st-purple {
  color: #733FC0;
}
.st-blue {
  color: #3B73C5;
}
.st-blue-title {
  color: rgb(234, 242, 255);
  font-size:20px;
  font-weight: bold;
}
.st-darkblue-title {
  color: #1D3962;
  font-size:35px;
  font-weight: bold;
}
.st-blue-title-30 {
  color: #3B73C5;
  font-size:30px;
  font-weight: bold;
}
.st-blue-title-25 {
  color: #3B73C5;
  font-size:30px;
  font-weight: bold;
}
.st-white {
  color: #FFFFFF;
}
.st-gray {
  color: #c9c9c9;
}
.st-darkblue {
  color: #1D3962;
}
.srd-25 {
  border-radius: 25px;
}
.sbn-navbar {
    color: #FFF;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    margin-left:8px;
    font-weight: bold;
    cursor: pointer;
}
.sbn-navbar-active {
    color: #3B73C5;
    background-color: #FFF;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    margin-left:8px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}
.sbn-navbar-invert {
    color: #3B73C5;
    background-color: #FFF;
    font-size: 14px;
    padding: 10px;
    margin-left:30px;
    font-weight: bold;
    cursor: pointer;
}
.sbn-navbar:hover {
    background-color: #FFF;
    color: #3B73C5;
    padding: 10px;
    border-radius: 10px;
    margin-left:8px;
    text-decoration: none;
}
.sbn-navbar-invert:hover {
    color: #3B73C5;
    background-color: #FFF;
    padding: 10px;
    font-weight: bold;
    text-decoration: none;
}
</style>
